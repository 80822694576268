@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
    margin: 0;
    padding: 0;
    background-image: url(../../../../public/GA/images/background-ga.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
}

.formulaire {
    width: 90%; /* Utilisation d'une valeur relative pour la largeur */
    max-width: 536px; /* Ajout d'une limite de largeur pour une meilleure responsivité */
    height: 304.5px;
    padding: 20px; /* Ajout de marges internes pour l'espace autour du contenu */
    box-sizing: border-box; /* Inclut les marges internes dans la largeur totale */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 5px 50px #000000BD;
    border: 1px solid #E5E5E5;
    border-radius: 37px;
    opacity: 1;
    margin: 20px; /* Ajout de marges extérieures pour l'espacement entre les éléments */
    position: relative;
}

.logo {
    position: absolute; /* Position absolue par rapport au conteneur .formulaire */
    top: -57.175px; /* Alignez le haut du logo au sommet de .formulaire */
    left: 50%; /* Alignez la moitié gauche du logo au centre de .formulaire */
    transform: translateX(-50%); /* Déplacez le logo de moitié vers la gauche pour le centrer */
}

.formulaire h1 {
    font-size: 22px; /* Utilisation d'une valeur relative pour la taille de la police */
    text-align: center;
    color: #085F61;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    margin-top: 18.36px; /* Espacement supplémentaire vers le haut */
    margin-bottom: 11.5px;
}

.formulaire .form-control {
    width: 302.36px;
    height: 48.76px;
    border: 1px solid #CCCCCC;
    font-size: 18px;
    border-radius: 27px;
    text-align: center;
}

.formulaire .form-control::placeholder {
    color: #ABABAB; /* Couleur du placeholder */
}

.formulaire .form-field {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formulaire label {
    text-align: center;
    margin-bottom: 5px;
    font-weight: bold;
}

.forgot-password {
    color: #085F61;
    text-align: center;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    margin-top: 8.24px;
}

button {
    width: 240px;
    height: 47px;
    background: #085F61;
    box-shadow: 0px 5px 15px #00000029;
    border-radius: 21px;
    color: white;
    font-family: 'Poppins', sans-serif;
    border: none;
    margin-top: 10px;
    position: absolute;
    bottom: -23.5px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    cursor: pointer;
}

.button-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-text {
    margin-right: 11px; /* Espacement de 11px entre le texte et le SVG */
}

.interface{
    margin-top: 40px;
    position: absolute;
    bottom: 80px; /* Ajustez cette valeur selon votre préférence */
    right: 60px;
    a{
        padding: 11px 19px;;
        background: #FFFFFF;
        box-shadow: 0px 5px 15px #00000066;
        border-radius: 21px;
        color: #085F61;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        text-decoration: none; 
    }
}

p {
    position: absolute;
    bottom: 5px; /* Ajustez cette valeur selon votre préférence */
    left: 50%;
    transform: translateX(-50%); /* Pour centrer horizontalement */
    color: #FFFFFF;
    text-align: center;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    text-shadow: 0px 3px 6px #00000029;
    margin-top: 40px; /* Espacement supplémentaire vers le haut */
}


@media screen and (max-width: 1150px) {
    .interface {
        width: 100%;
        position: absolute;
        bottom: 80px; /* Ajustez cette valeur selon votre préférence */
        left: 50%;
        transform: translateX(-50%); /* Pour centrer horizontalement */
        text-align: center; /* Pour centrer le texte à l'intérieur */
    }
}